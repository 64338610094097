











import { Component, Vue } from 'vue-property-decorator';
import { paragraph1, paragraph2, paragraph3 } from './aboutContent';

@Component({})
export default class About extends Vue {
  paragraph1: string = paragraph1;
  paragraph2: string = paragraph2;
  paragraph3: string = paragraph3;
}
